/* @import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/**/
/*
font-family: "Open Sans", sans-serif;
font-family: "Poppins", sans-serif;
*/

:root {
    --primary-color: #f7931e;
    --label-color-1: #f7931e;
    --label-color-2: #4b4c4c;
    --primary-font: "Poppins", sans-serif;
    --title-font: "Poppins", sans-serif;
    --sub-title: "Open Sans", sans-serif;
    --p-fonts: "Open Sans", sans-serif;
}
* {
    scrollbar-width: thin;
}
html, body {
    font-family: var(--p-fonts);
}

body {
    background: #fff !important;
}

p {
    line-height: 28px;
    margin-bottom: 0 !important;
}
a p, p a, span, p span, a span, p {
    font-size: 14px;
}
p, a, li, ul, ol, span, div, input, select, button {
    font-family: var(--p-fonts);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--title-font);
}

h2 {
    font-size: 28px;
}
label {
    font-family: var(--p-fonts);
    font-weight: 600;
    color: #000;
    font-size: 14px;
}

.btn-currency {
    font-size: 15px !important;
    height: 42px !important;
    border: 1px solid #ccc !important;
    font-family: var(--p-font) !important;
    border-radius: 0 !important;
}

input[type="text"], input[type="password"], input[type="tell"], input.form-control {
    padding: 5px 12px !important;
    font-family: var(--p-fonts) !important;
    min-height: 42px !important;
    font-size: 14px !important;
}

 select {
    padding: 5px 12px !important;
    font-family: var(--p-fonts) !important;
    min-height: 42px !important;
    font-size: 14px !important;
    border: none;
}

select option {
    font-family: var(--p-fonts) !important;
    padding: 5px 12px !important;
    font-size: 14px !important;
}

.form-control {
    font-size: 14px !important;
    font-family: var(--p-fonts);
    -webkit-appearance: auto !important;
    appearance: auto !important;
}

.btn-warning {
    background-color: var(--primary-color) !important;
}

.css-1h9h5al-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
}

/* Navigation area */
.navbar {
    background: #fff !important;
    transition: all 230ms linear;
}
.navbar a {
    font-size: 15px !important;
    font-family: var(--primary-font);
    color: #333 !important;
    font-weight: 500 !important;
    vertical-align: middle;
    text-transform: capitalize;
    padding: 10px;
}
.navbar.scrolled {
    box-shadow: 0 10px 50px -16px #00000069;
    transition: all 230ms linear;
}
.prox-logo img {
    max-width: 100%;
    width: 180px;
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: inherit !important;
    margin-top: var(--bs-dropdown-spacer);
}

.account-name span{
    font-size: 15px !important;
    font-family: var(--primary-font);
}

.account-name span.profile-icon {
    padding: 2px 8px;
    background: var(--primary-color);
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    margin-right: 5px;
    color: #fff;
}

/* Banner */
.home-search {
    margin-top: 4%;
}

.select-sector {
    background: #fff !important;
    height: 42px;
    border-radius: 0 !important;
    border: 1px solid #ccc !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
    font-family: var(--p-fonts) !important;
    font-size: 14px !important;
    color: rgb(96 96 96) !important;
}

.home-search .input-group .btn {
    height: 42px;
    font-family: var(--p-fonts) !important;
    font-size: 14px !important;
}
.select-position, .select-location {
    scrollbar-width: thin;
}
.select-position li, .select-location li{
    font-size: 14px;
}
.dropdown-menu.select-sector a {
    background: #fff !important;
    border-bottom: 1px solid #ccc;
    font-size: 13px;
    color: rgb(96 96 96) !important;
    font-family: var(--p-fonts) !important;
}


.banner-wrapper {
    background: url(../images/banner-bg.jpg) center no-repeat;
    background-size: cover;
    height:auto;
    margin: auto;
    width: 100%;
    max-width: 100%;
    position: relative;
    padding:50px 0;
}
.banner-wrapper .banner-content {
    margin-top: 25px;
}
.banner-wrapper .home-search h2 {
    font-size: 38px;
    font-weight: 600;
    font-family: var(--title-font);
}

.banner-content h2 {
    font-size: 28px;
    font-weight: 600;
}

.banner-wrapper h3 {
    font-family: var(--p-font);
    font-weight: 600;
    letter-spacing: normal;
    font-size: 22px;
}

.banner-wrapper .btn-warning {
    font-size: 16px;
    background: #fff !important;
    color: #333 !important;
    font-family: var(--primary-font);
    padding: 10px 28px;
    border: 2px solid var(--primary-color);
}

.search-btn {
    background: var(--primary-color);
    color: #fff;
}

.inner-banner {
    width: 100%;
    height: 180px;
    background: #ccc;
    background: url(../images/banner-bg.jpg) center no-repeat;
    position: relative;
    background-size: cover;
    padding: 110px 0 0 0;
    text-align: left;
}
.inner-banner h2 {
    font-family: var(--primary-font);
    font-size: 22px;
    font-weight: 600;
    position: relative;
    padding-bottom: 5px;
}
.inner-banner h2::before {
    content: '';
    width: 50px;
    height: 2px;
    background: var(--primary-color);
    position: absolute;
    left: 0;
    bottom: 0;
}

/* mouse scrolling */
.scroll-downs {
    position: absolute;
    right: 0;
    bottom: 30px;
    left: 0;
    margin: auto;
    
    width :34px;
    height: 55px;
  }
  .mousey {
    width: 3px;
    padding: 6px 11px;
    height: 28px;
    border: 2px solid var(--primary-color);
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
  }
  .scroller {
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: var(--primary-color);
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
    animation-iteration-count: infinite;
  }
  @keyframes scroll {
    0% { opacity: 0; }
    10% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(15px); opacity: 0;}
  }


.btn-warning {
    background-color: var(--label-color-1);
    color: #fff;
    font-family: var(--primary-font);
    font-size: 15px;
    padding: 10px 20px;
}

.btn-outline {
    border: none !important;
    border-bottom: 2px solid var(--label-color-1) !important;
    font-family: var(--primary-font) !important;
    border-radius: 0px !important;
    padding: 0 0 5px 0 !important;
}

.title-wrapper {
    position: relative;
    margin: 30px auto;
}
.title-wrapper h4 {
    margin-bottom: 0;
    font-family: var(--sub-title);
    font-size: 17px;
    line-height: 18px;
    color: var(--label-color-1);
}

.title-wrapper h2 {
    margin-bottom: 0;
    font-family: var(--title-font);
    color: var(--label-color-2);
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
}

.title-wrapper h3 {
    margin-bottom: 0;
    font-family: var(--primary-font);
    font-size: 22px;
    color: var(--label-color-2);
    line-height: 22px;
}
.step-box {
    background: #fff;
    text-align: center;
    border: 1px solid #eaeaea;
    padding:30px 20px;
    border-radius: 8px;
    box-shadow: 0 0 25px -15px #707070;
    position: relative;
}
.step-box > .icon > .material-symbols-outlined {
    font-size: 50px;
    color: var(--label-color-1);
}

.step-box .icon ~ h2 {
    font-size: 17px;
    font-family: var(--p-fonts);
    font-weight: 600;
}

.step-box .icon ~ h2 ~ p {
    font-size: 15px;
    
}

.featured-project {
   background: #fff;
    text-align: center;
    border: 1px solid #eaeaea;
    padding:30px 20px;
    border-radius: 8px;
    box-shadow: 0 0 25px -15px #707070;
    position: relative;
    text-align: left
}

.featured-project .short-desc p {
    font-size: 13px;
    color: #7a7a7a;
    line-height: 21px;
    font-weight: 500;
}

.featured-project .btn-warning {
    font-size: 13px;
    color: #fff;
}

.featured-project .company-info {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    vertical-align: middle;
    margin-bottom: 10px;
    
}

.featured-project .company-info .logo {
    width: 40px;
    height: 40px;
    vertical-align: middle;
    border: 1px solid #525252;
    border-radius: 5px;
    text-align: center;
    line-height: 40px;
    font-weight: 700;
    background: #525252;
    color: #fff;
}

.featured-project .info {
    position: relative;
    padding-left: 10px;
    text-align: left;
    width: 85%;
    max-width: 80%;
}
.featured-project .info h2 {
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #1a1a1a;
}
.featured-project .info p {
    margin-bottom: 0
}

.featured-project .btn-outline {
    font-size: 12px !important;
}

.call-to-action-1 .image-area {
    position: relative;
}
.call-to-action-1 .image-area img{
    max-width: 100%;
    width: 100%;
}
/* .call-to-action-1 .image-area:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 80%;
    height: 70%;
    background: var(--label-color-1);
    left: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 0 50px 100px 0;
    transform-origin: bottom left;
    -ms-transform: skew(-20deg, 0deg);
    -webkit-transform: skew(-20deg, 0deg);
    transform: skew(-20deg, 0deg);
    
}
.call-to-action-1 .image-area:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 70%;
    height: 70%;
    background: var(--label-color-1);
    left: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 0 50px 100px 0;
} */
.call-to-action-1 .image-area img {
    z-index: 9;
    position: relative
}

.call-to-action-1 .content-area {
 position: relative;
}
.call-to-action-1 .content-area h2 {
    font-weight: 700;
    font-size: 38px;
}

.call-to-action-1 .content-area .btn.btn-warning {
    background: var(--primary-color) !important;
    color: #fff !important;
    font-family: var(--primary-font);
}

.job-category-home {
    position: relative
}

.job-category-home .catlist {
    display: block;
    text-decoration: none;
    width: 100%;
    text-align: center;
    
}
.job-category-home .catlist h2 {
    font-size: 15px;
    color: var(--label-color-2);
    font-weight: 500;
    margin-bottom: 0;
}
.job-category-home .catlist p {
    color: #a19e9e;
    font-size: 13px;
    font-family: var(--p-fonts);
}

.footer-wrapper {
    background: #2d2d2d !important;
}

/* login page */
.login-wrapper {
    width: 100%;
    height: auto;
    position: relative;
}

.login-box {
    background: #fff;
    /* background:url(../images/login-bg.jpg) no-repeat; */
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    box-shadow: 0 0 33px -16px;
    border: 1px solid #ccc;
}

.login {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid var(--primary-color);
    box-shadow: 0 8px 15px 0px #9f9f9f;
}

.login-box p {
    font-size: 15px;
}

.signup-wrapper {
    margin: auto;
    width: 100%;
    position: relative;
}
.signup-box {
    background: #fff;
    /* background:url(../images/login-bg.jpg) no-repeat; */
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    box-shadow: 0 0 33px -16px;
    /* border: 2px solid var(--primary-color); */
    border: 2px;
    box-shadow: 0 8px 15px 0px #9f9f9f;
}

.sign-up-box-header{
    background: #fff;
    padding:20px 20px 0;
    border-radius: 10px;
    box-shadow: 0 0 15px -7px #e4e4e4;
}

.sign-up-box-header button {
    font-size: 14px;
    font-weight: 600;
    font-family: var(--primary-font);
}

.sign-up-box-header p {
    font-size: 20px;
    font-weight: 600;
    font-family: var(--primary-font);
    color: var(--primary-color);
}

.project-filters {
    background: #fff;
    padding: 15px;
    margin-top: 15px;
    box-sizing: border-box;
    box-shadow: 0px 25px 30px rgb(84, 84, 84, 0.2);
    position: sticky;
    border-radius: 15px;
}

.location-fild label, .project-filters label{
    font-family: var(--title-font);
    font-size: 13px;
    font-weight: 600;
}
.MuiAutocomplete-input {
    padding: 0;
}

.location-fild input {
    padding: 0 !important;
}
.location-fild button.btn, .project-wise-search button.btn {
    font-family: var(--title-font);
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    width: 40px;
    height: 42px;
    right: 0px ;
    bottom: 0;
    padding: 0;
    text-align: center;
}
.project-wise-search input {
    border: 1px solid #ccc;
}

.location-fild button.btn span, .project-filters button.btn span{
    vertical-align: middle;
}

.project-filters .btn-warning {
    font-size: 14px;
    color: #fff;
}

.project-list {
    position: relative;
}
.company-title a h2 {
    font-size: 16px;
    color: #333;
    font-family: var(--primary-font);
    margin-bottom: 2px;
    font-weight: 600;
}

.company-title p {
    font-size: 14px;
    font-family: var(--p-fonts);
    text-transform: capitalize;
    margin-bottom: 0;
}

.post-meta {
    border-left: 1px solid #ccc;
}
.post-meta p {
    line-height: 20px;
}
.post-meta p i {
    vertical-align: middle;
}
.post-meta p span {
    font-family: var(--p-fonts);
    font-size: 13px !important;
    vertical-align: middle;
    font-weight: 600;
}

.meta-info p {
    margin-bottom: 0;
}
.meta-info p i {
    vertical-align: middle;
}

.meta-info label {
    color: #898989;
    font-size: 13px;
}
.meta-info h6 {
    font-size: 13px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 18px;
}

.project-list:hover .company-title a h2 {
    transition: all 230ms linear;
}

.project-details-container .alert-warning p{
    font-size: 15px;
}
.profile-wrapper {
position: relative;
}

.profile-wrapper img {
    width: 80px;
    height: 60px;
    border-radius: 10px;
    object-fit: scale-down !important;
    border: 1px solid #ccc;
}
.profile-wrapper .first-letter {
    width: 80px;
    height: 60px;
    border-radius: 10px;
    object-fit: scale-down !important;
    border: 1px solid #ccc;
}

.profile-wrapper p.letter-cap {
    display: block;
    width: 80px;
    height: 60px;
    background: #fff;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    font-family: var(--primary-font);
    line-height: 60px;
    border: 1px solid #ccc;
    border-radius: 8px;
    text-transform:uppercase;
}

.project-details-container .company-info, .single-job-wrapeer .company-info{
    background: #fff;
    width: 100%;
    border: 1px solid #ccc;
    height: 100%;
    position: relative;
    padding: 20px 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px -3px #ccc;
}
.project-details-container .company-info .company-logo,
.single-job-wrapeer .company-info .company-logo {
    position: relative;
    margin: auto;
    text-align: center;
    background-color: #fff;
}
.project-details-container .company-info .company-logo img,
.single-job-wrapeer .company-info .company-logo img {
    margin: auto;
    max-width: 100%;
    width: 112px;
    height: 112px;
    object-fit: contain;
    border: 3px solid var(--primary-color);
    border-radius: 100%;
    padding: 8px;
}

.project-details-container .company-info .btn-share, .single-job-wrapeer .company-info .btn-share {
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid #ccc !important;
    line-height: 45px !important;
    width: 36px;
    height: 36px;
    text-align: center;
    padding: 0;
    border-radius: 100%;
}
.project-details-container .company-info .btn-share span {
    font-size: 19px !important;
}
.project-details-container .company-info h2,
.single-job-wrapeer .company-info h2 {
    font-size: 17px !important;
    color: #333;
    letter-spacing: inherit;
    font-weight: 600;
    margin-bottom: 0;
}
.single-job-wrapeer .company-info .name {
    text-align: center;
    margin-top: 15px;
}
.single-job-wrapeer .company-info .name a{
    font-size: 15px;
    color: #333;
    font-weight: 500;
}

.project-details-container .project-info h2,
.single-job-wrapeer .project-info h2 {
    font-size: 17px !important;
    line-height: 26px;
    color: #333;
    letter-spacing: inherit;
    font-weight: 600;
}

.project-details-container .project-info p,
.single-job-wrapeer .project-info p {
    margin-bottom: 0px !important;
    font-weight: 600 !important;
    letter-spacing: inherit;
    font-family: var(--title-font) !important;
}

.single-job-wrapeer .project-info p i {
    vertical-align: middle;
}

.project-details-container .project-details h2,
.single-job-wrapeer .project-details h2 {
    font-size: 18px;
    margin-bottom: 0 !important;
}

.releted-job-box h6{
    font-size: 15px;
    color: #5a5a5a;
    font-weight: 500;
}

.releted-job-box label {
    color: #898989;
    font-size: 13px;
    font-family: var(--primary-font);
} 
.releted-job-box label .material-symbols-outlined {
    font-size: 14px;
    vertical-align: middle;
}
.releted-job-box p{
    margin-bottom: 0px !important;
    font-weight: 600 !important;
    letter-spacing: inherit;
    font-family: var(--title-font) !important;
    font-size: 13px;
}

.project-details-container .project-details ul.nav-pills li{
    font-size: 15px;
    font-family: var(--primary-font);
}
.project-details-container .project-details .project-meta h3{
    font-size:22px;
    font-weight: 600;
}

.project-details-container .project-details .company-title h2 {
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
}

.project-info label {
    color: #898989;
    font-size: 13px;
    font-family: var(--primary-font);
}
.project-info p{
    font-size: 13px;
    line-height: 18px;
}

.more-info-meta label {
    font-size: 16px;
    font-family: var(--primary-font);
}
.more-info-meta p {
    line-height: 22px;
    font-weight: 500;
}
.more-info-meta p a{
    color: #2e2e2e;
    font-size: 14px;
    font-family: var(--primary-font);
    line-height: inherit;
}

.required-position ul {
    margin: auto;
    padding: 0;
}
.required-position ul li {
    display: inline-block;
    list-style: none;
    border: 1px solid #ccc;
    background: #fff;
    padding: 2px 10px;
    margin-right: 5px;
    width: auto;
    border-radius: 5px;
}

.required-position ul li a {
    text-align: center;
    font-size: 14px;
    text-transform: capitalize;
    color: #333;
    line-height: 15px;
    font-weight: 500;
}

.location-fild {
    position: relative;
}

.job-title {
    position: relative;
}
.job-title h5 {
    margin-bottom: 0;
}
.job-title h5 a {
    color: #000;
    font-size: 17px;
    font-weight: 700;
}

.job-listing-row .comp-logo img {
    width: 80px;
    height: 60px;
    border-radius: 10px;
    object-fit: scale-down !important;
    border: 1px solid #ccc;
}

.action-buttons {
    text-align: right;
}
.action-buttons .btn {
    width: 32px;
    height: 32px;
    padding: 0;
    line-height: 34px;
    border-radius: 100%;
}
.action-buttons .btn i {
    font-size: 17px;
}

/* Footer */

.footer-top {
    background: #f5f5f5;
    padding: 60px 0 30px 0;
    box-sizing: border-box;
}

.footer-top .brand-name {
    position: relative;
}
.footer-top .brand-name img {
    width:150px;
    max-width: 100%;
}

.about-section-footer p {
    font-family: var(--p-fonts);
    font-size: 15px;
    color: #787878;
    font-weight: 500;
    line-height: 24px;
}
.footer-top .quicklinks h2 {
    font-size: 17px;
    font-family: var(--primary-font);
    position: relative;
}
.footer-top .quicklinks ul {
    margin: auto;
    padding: 0;
}
.footer-top .quicklinks ul li {
    list-style: none;
}

.footer-top .quicklinks ul li a {
    font-size: 14px;
}

.footer-copyright {
    background: #dfdfdf;
    padding: 10px;
    text-align: center;
    position: relative;
}
.footer-copyright p {
    font-family: var(--primary-font);
    font-size: 13px;
}

.CookieConsent {
    background: rgba(0,0,0,0.7) !important;
    font-family: var(--primary-font);
    font-size: 14px;
}
#rcc-decline-button, #rcc-confirm-button {
    border-radius: 6px !important;
    padding: 5px !important;
    margin: 4px !important;
    font-size: 13px;
  }
.CookieConsent > div  {
    flex: 1 0 300px;
    margin: 8px !important;
    text-align: center;
  }


.releted-options {
    margin-top: 30px;
}
.project-status {
    margin-left: 5px;
}
.project-status i {
    font-size: 14px;
}

.pagination {
    text-align: center;
}
.pagination .MuiBox-root.css-1wuttcd {
    margin: 15px auto;
}

.work-exprience ul li {
    font-size: 13px;
    vertical-align: middle;
    font-weight: 500;
    margin-bottom: 5px;
}
.work-exprience ul li span.material-icons {
    vertical-align: middle;
    font-size: 14px;
    
}

.professional-listing-wrapper .project-list .profile-wrapper img{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit:cover !important;
    object-position: top;
    border: 1px solid #ccc;
}

.professional-profile {
    position: relative;
}

.professional-profile img{
    width: 180px;
    height: 180px;
    max-width: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 5px;
}

.professional-details-wrapper .meta-data h2{
    font-size: 23px;
    font-weight: 600;
}
.meta-info-1 h2 {
    font-size: 23px !important;
    font-weight: 600;
}
.meta-info-1 h4 {
    font-size: 17px !important;
    font-weight: 600;
}
.meta-info-1 ul li, .meta-info-1 ul li span{
    font-size: 15px;
    font-weight: 600;
}

.org-list-wrap .meta-info {
    text-align: left;
    margin-left: 10px;
}

.profile-page .user-profile-left .profile-image-holder{
    position: relative;
}
.profile-page .user-profile-left .profile-image-holder {
    position: relative;
    margin: auto;
    text-align: center;
    width: 165px;
    height: 165px;
}
.profile-page .user-profile-left .profile-image-holder label {
    position: absolute;
    background: #fff;
    width: 32px;
    height: 32px;
    cursor: pointer;
    color: #07ad7c;
    border: 2px solid #07ad7c;
    border-radius: 100%;
    bottom: 24px;
    right: 14px;
}
.profile-page .user-profile-left {
    border: 1px solid #ccc;
    padding: 15px;
}
.profile-page .user-profile-left .profile-image-holder label span {
    font-size: 16px;
    line-height: 26px;
}
.profile-page .user-profile-left .profile-image-holder img{
    position: relative;
    width: 150px;
    max-width: 100%;
    height: 150px;
    padding: 8px;
    object-fit: cover;
    object-position: top;
    border: 3px solid #07ad7c;
    border-radius: 100%;
}
.profile-meta h4 {
    font-size: 18px;
    font-weight: 600;
}
.profile-meta h6 {
    font-size: 15px !important;
    font-weight: 500;
}
.profile-page .user-profile-left .profile-meta span.material-symbols-outlined {
    vertical-align: middle;
    font-size: 17px;
    color: #b1b1b1;
    line-height: 24px;
}

.altr-profile-data p, .altr-profile-data p.edit-btn span:nth-of-type(2){
    font-size: 13px;
    cursor: pointer;
    font-weight:600;
}
.delete-btn:hover, .delete-btn:hover span {
    color: #f00 !important;
    transition: all 230ms linear;
}
.altr-profile-data p i, .altr-profile-data p span {
    vertical-align: middle;
    font-size: 15px;
}

.user-information p {
    font-size: 15px;
    font-family: var(--p-font);
}

.modal-header .modal-title {
    font-size: 18px;
    font-family: var(--primary-font);
    font-weight: 600;
    color:var(--primary-color);
}
button.close {
    border-radius: 4px;
    padding: 5px;
    line-height: 5px;
    border: none;
    background: no-repeat;
}

.modal-body .dropdown-heading .dropdown-heading-value span,
.modal-body input[type="text"],
.modal-body input[type="number"],
.modal-body input[type="tel"] {
    font-size: 14px;
    font-family: var(--p-font);
}
.modal-footer button{
    font-family: var(--primary-font);
    font-size: 15px;
}

.user-information .card-header h5 {
    font-size: 18px;
    font-family: var(--primary-font);
    margin-bottom: 0;
}
.user-information .card-header .material-symbols-outlined {
    font-size: 17px;
    vertical-align: middle;
}
.user-information .accordion .accordion-header .accordion-button {
    font-size: 14px;
    font-family: var(--primary-font);
    font-weight: 500;
}

.accordion-body {
    position: relative;
}
.accordion-body .buttons {
    position: absolute;
    top: 0;
    right: 0;
}
.accordion-body .meta-info h5, .accordion-body .meta-info a h5  {
    font-size: 17px;
    font-weight: 600;
    color: #333;
}
.accordion-body .meta-info a h5 span.material-symbols-outlined {
    vertical-align: middle;
    color: #2497f5;
}
a.btn-more{
    font-size: 14px;
    font-weight: 600;
    border-bottom: 2px solid #ccc;
}
button.btn-more {
    font-size: 14px;
    font-weight: 600;
    border: none;
    background: #fff;
    border-bottom: 2px solid #ccc;
    color: #2497f5;

}

.accordion-body .buttons .edit-btn, .accordion-body .buttons .delete-btn {
    display: inline-block;
}
.accordion-body .buttons .edit-btn span.material-symbols-outlined,
.accordion-body .buttons .delete-btn span.material-symbols-outlined {
    font-size: 15px;
    cursor: pointer;
    padding: 4px;
    border: 1px solid #ccc;
    margin-right: 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.employer-profile .user-profile-left {
    border: 1px solid #ccc;
    padding: 15px;
}

.employer-profile .user-profile-left .profile-image-holder {
    position: relative;
    margin: auto;
    text-align: center;
    width: 165px;
    height: 165px;
}

.employer-profile .user-profile-left .profile-image-holder img{
    position: relative;
    width: 150px;
    max-width: 100%;
    height: 150px;
    padding: 8px;
    object-fit:scale-down;
    object-position: top;
    border: 3px solid var(--primary-color);
    border-radius: 100%;
}

.employer-profile .user-profile-left .profile-image-holder label {
    position: absolute;
    background: #fff;
    width: 32px;
    height: 32px;
    cursor: pointer;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    border-radius: 100%;
    bottom: 24px;
    right: 14px;
}
.employer-profile .user-profile-left .profile-image-holder label span {
    font-size: 16px;
    line-height: 26px;
}
.employer-profile .profile-meta h4 {
    text-align: center;
}

.dropdown-heading-value {
    font-size: 15px;
    font-family: var(--p-font);
}

.my-projects-landingpage .card a h5 {
    font-size: 16px;
    color: #333;
    font-family: var(--primary-font);
    margin-bottom: 2px;
    font-weight: 600;
}

.applicants_profile span {
    vertical-align: middle;
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 5px;
}

.sign-up-cards{
    background: #fff;
    /* background:url(../images/login-bg.jpg) no-repeat; */
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    box-shadow: 0 0 33px -16px;
    border: 2px solid var(--primary-color);
    box-shadow: 0 8px 15px 0px #9f9f9f;
}

/* Responsive */

@media screen and (min-width:320px) and (max-width:767px) {
    .banner-wrapper .home-search h2 {
        font-size: 20px;
    }
    .banner-content h2 {
        font-size: 18px;
        font-weight: 600;
    }
    .banner-wrapper h3 {
        font-size: 15px;
    }
    .home-search .input-group {
        display: block;
    }

    .home-search input, .home-search .select-sector{
        width: 100% !important;
        margin-bottom: 5px;
        border: none !important;
        border-radius: 0 !important;
        text-align: left;
    }
    .title-wrapper {
        position: relative;
        margin: 15px auto;
        text-align: center;
    }
    .title-wrapper h2 {
        font-size: 20px;
    }
    .inner-banner {
        width: 100%;
        height: auto;
    }
    .about-section-footer {
        margin-bottom: 18px;
      }
    .about-section-footer p {
        font-family: var(--p-fonts);
        font-size: 13px;
        color: #787878;
        font-weight: 500;
        line-height: 20px;
      }
      .footer-top {
        background: #f5f5f5;
        padding: 30px 0 30px 0;
        box-sizing: border-box;
        margin-top: 15px;
      }
      .footer-top .quicklinks h2 {
        font-size: 15px;
      }
      .footer-top .quicklinks ul li a {
        font-size: 12px;
      }
}