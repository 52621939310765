@import url('https://fonts.googleapis.com/css2?family=Oswald&family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
	body {
		margin: 0 !important;
	}
}
.select-input-styles {
    padding: 15px 0;
}

.select-input-styles svg {
    display: block;
    width: 2em;
    height: 2em;
    top: calc(50% - 1em);
}
.ck-editor__editable {
    height: 400px; /* Set the desired height */
  }
  
.toast-message {
    font-size: 20px;
  }

  @keyframes modalFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .modal.fade .modal-dialog {
    animation: modalFadeIn 0.3s ease-in-out;
  }
  

  .thankyou-wrapper {
    margin: auto;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    position: relative
  }
  
  .icon_wrapp {
    display: inline-block;
    line-height: 100px;
    border-radius: 100%;
    margin: auto;
    box-sizing: border-box;
    text-align: center;
    position: relative;
  }
  
  .icon_wrapp span {
    font-size: 98px;
    color: #02D879;
    text-align: center;
    margin: 20px auto;
    position: relative;
    display: block;
  }
  
  .content {
    text-align: center;
    position: relative
  }
  
  .content h2 {
    font-size: 20px;
  }  
  .text-secondary {
    color: #15395A !important;
}
.font-weight-600 {
  font-weight: 600;
}
.text-sm{
  font-size: 14px;
}

.fs-18 {
  font-size: 18px;
}

/* Contact us page */
.contact3 {
  color: #8d97ad;
  font-weight: 500;
}

.contact3 h1,
.contact3 h2,
.contact3 h3,
.contact3 h4,
.contact3 h5,
.contact3 h6 {
  color: #080809;
}

.contact3 .font-weight-medium {
  font-weight: 700;
}

.contact3 .card-shadow {
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.contact3 .btn-danger-gradiant {
  background: linear-gradient(to right, #ff4d7e, #ff6a5b);
}

.contact3 .btn-danger-gradiant:hover {
  background: linear-gradient(to right, #ff6a5b, #ff4d7e);
}

/* End Contact us page */

.blurred-background {
  filter: blur(5px); /* Adjust the blur amount as needed */
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f5f5f5; /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Above other content */
}

.font-italic{
  font-style: italic;
}

/* by UI team */
.prox-logo {
  width: 150px;
  max-width: 140px;
}
.prox-logo img {
  max-width: 100%;
}

.MuiBox-root{
  max-width: 100% !important;
  min-height: 100% !important;
  height: auto !important;
}
.side-nav-area .MuiBox-root {
  min-height: auto !important;
}



.main-container {
	font-family: 'Lato';
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
}

.ck-content {
	font-family: 'Lato';
	line-height: 1.6;
	word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
	min-width: 795px;
	max-width: 795px;
}

.ck-content h3.category {
	font-family: 'Oswald';
	font-size: 20px;
	font-weight: bold;
	color: #555;
	letter-spacing: 10px;
	margin: 0;
	padding: 0;
}

.ck-content h2.document-title {
	font-family: 'Oswald';
	font-size: 50px;
	font-weight: bold;
	margin: 0;
	padding: 0;
	border: 0;
}

.ck-content h3.document-subtitle {
	font-family: 'Oswald';
	font-size: 20px;
	color: #555;
	margin: 0 0 1em;
	font-weight: bold;
	padding: 0;
}

.ck-content p.info-box {
	--background-size: 30px;
	--background-color: #e91e63;
	padding: 1.2em 2em;
	border: 1px solid var(--background-color);
	background: linear-gradient(
			135deg,
			var(--background-color) 0%,
			var(--background-color) var(--background-size),
			transparent var(--background-size)
		),
		linear-gradient(
			135deg,
			transparent calc(100% - var(--background-size)),
			var(--background-color) calc(100% - var(--background-size)),
			var(--background-color)
		);
	border-radius: 10px;
	margin: 1.5em 2em;
	box-shadow: 5px 5px 0 #ffe6ef;
}

.ck-content blockquote.side-quote {
	font-family: 'Oswald';
	font-style: normal;
	float: right;
	width: 35%;
	position: relative;
	border: 0;
	overflow: visible;
	z-index: 1;
	margin-left: 1em;
}

.ck-content blockquote.side-quote::before {
	content: '“';
	position: absolute;
	top: -37px;
	left: -10px;
	display: block;
	font-size: 200px;
	color: #e7e7e7;
	z-index: -1;
	line-height: 1;
}

.ck-content blockquote.side-quote p {
	font-size: 2em;
	line-height: 1;
}

.ck-content blockquote.side-quote p:last-child:not(:first-child) {
	font-size: 1.3em;
	text-align: right;
	color: #555;
}

.ck-content span.marker {
	background: yellow;
}

.ck-content span.spoiler {
	background: #000;
	color: #000;
}

.ck-content span.spoiler:hover {
	background: #000;
	color: #fff;
}

.ck-content pre.fancy-code {
	border: 0;
	margin-left: 2em;
	margin-right: 2em;
	border-radius: 10px;
}

.ck-content pre.fancy-code::before {
	content: '';
	display: block;
	height: 13px;
	background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCAxMyI+CiAgPGNpcmNsZSBjeD0iNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGMzZCNUMiLz4KICA8Y2lyY2xlIGN4PSIyNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGOUJFNEQiLz4KICA8Y2lyY2xlIGN4PSI0Ny41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiM1NkM0NTMiLz4KPC9zdmc+Cg==);
	margin-bottom: 8px;
	background-repeat: no-repeat;
}

.ck-content pre.fancy-code-dark {
	background: #272822;
	color: #fff;
	box-shadow: 5px 5px 0 #0000001f;
}

.ck-content pre.fancy-code-bright {
	background: #dddfe0;
	color: #000;
	box-shadow: 5px 5px 0 #b3b3b3;
}
.custom-loader{
	width: 100%;
    height: auto;
    max-width: 230px;
}


@media (max-width: 768px) { /* Tablet */
    .custom-loader {
        max-width: 180px;
    }
}

@media (max-width: 480px) { /* Mobile */
    .custom-loader {
        max-width: 180px;
    }
}
